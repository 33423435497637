import React from 'react';
import { Link } from 'react-router-dom';

const AnalistEvent: React.FC = () => {
    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center lg:h-48 sm:h-56" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl lg:text-5xl font-bold">Análisis de Episodios Atmosféricos </h1>
                    <br/>
                    <div className='grid grid-cols-3 lg:grid-cols-5 gap-2 font-bold underline text-lg'>
                    <Link to="/analisis-atmosfericos/episodio-2015" className="block lg:px-2 py-2 hover:bg-primary rounded-md">Episodio 2015</Link>
                    <Link to="/analisis-atmosfericos/episodio-2016" className="block lg:px-2 py-2 hover:bg-primary rounded-md">Episodio 2016</Link>
                    <Link to="/analisis-atmosfericos/episodio-2017" className="block lg:px-2 py-2 hover:bg-primary rounded-md">Episodio 2017</Link>
                    <Link to="/analisis-atmosfericos/episodio-2018" className="block lg:px-2 py-2 hover:bg-primary rounded-md">Episodio 2018</Link>
                    <Link to="/analisis-atmosfericos/episodio-2019" className="block lg:px-2 py-2 hover:bg-primary rounded-md">Episodio 2019</Link>
                    </div>
                </div>
            </div>
            <div className="p-2 text-base sm:p-4 mx-auto">
                <p className='border border-primary rounded-md p-2 text-center justify-center'>
                La evidencia actual muestra los efectos perjudiciales de la contaminación del aire en la salud, afectando la mortalidad, morbilidad y aumentando el uso de servicios de urgencias por enfermedades respiratorias, cardiovasculares y cerebrovasculares. Estudios iniciales destacaron episodios graves en Meuse Valley (Bélgica, 1930), Donora (EE.UU., 1948) y Londres (1952). En Colombia, particularmente en el Área Metropolitana del Valle de Aburrá, se han registrado niveles elevados de material particulado en los últimos años, generando episodios significativos de contaminación del aire.                <br/>
                </p>
            </div>
            <div className="p-2 text-base sm:p-4 mx-auto ">
                <div className="border border-primary rounded-md p-4 text-justify" style={{ columnCount: 2, columnGap: '1.5rem' }}>
                    <p>
                    Estos episodios, según la literatura especializada, ocurren cuando los niveles de contaminación alcanzan, durante tres o más días consecutivos, concentraciones superiores a tres veces los niveles de referencia sugeridos por la Organización Mundial de la Salud (OMS). Para el caso de PM10, se establece en 150 µg/m³ (50x3); para PM2.5, en 75 µg/m³ (25x3); y para ozono, en 300 µg/m³ (100x3).
                    El equipo de investigadores del AMVA y la Universidad de Antioquia-FNSP ha documentado los efectos en la salud de los episodios ocurridos en Medellín y Girardota durante los años 2015 y 2016. En 2015 se ajustaron Modelos Aditivos Generalizados (GAM, regresión de Poisson), y en 2016 se utilizó el modelo Case Crossover (CCO, regresión logística condicional) con la serie de datos diarios de los trimestres de episodios en estudio.
                    En estos modelos, se consideró como variable de respuesta el número de casos diarios de defunciones, enfermos y urgencias en niños menores de 5 años y en adultos mayores de 65 años. Los modelos se ajustaron con los siguientes factores y covariables: días de la semana, feriados, fiestas, brotes de influenza, precipitación, temperatura y humedad relativa. Se estimaron los resultados de interés en términos de Riesgo Relativo (RR) y su correspondiente Intervalo de Confianza (IC95%), así como el incremento porcentual del riesgo (%RR) por aumentos de 10 µg/m³ en el promedio diario del contaminante. Los hallazgos más relevantes se detallan en la figura a continuación (Miguel Ángel Martínez-González. Bioestadística amigable. Tercera Edición, Editorial Elsevier. Barcelona, 2014).
                    Los episodios de contaminación atmosférica son causados por el incremento de las concentraciones de material particulado (PM10 y PM2.5) en dos periodos del año: febrero-abril y octubre-noviembre, influenciados por el ciclo anual de la precipitación en la zona andina. En marzo se presenta la transición entre la temporada seca y la primera temporada de lluvias, caracterizada por la presencia de capas de nubes de baja altura, lo que ocasiona la acumulación de contaminantes en la atmósfera y da lugar a las concentraciones más altas de material particulado del año. Posteriormente, en noviembre, se produce la segunda transición de temporada de lluvias la temporada seca, mes en el cual nuevamente se registra un aumento en las concentraciones de este contaminante.
                    Las fuentes móviles son los principales generadores de contaminantes criterios en el Valle de Aburrá y contribuyen con el 91% de las emisiones de material particulado fino (PM2.5). Las fuentes fijas, por su parte, aportan el 96% de las emisiones de óxidos de azufre, un gas precursor en la formación de PM2.5 en la atmósfera.
                    </p>
                    <br/>
                    <p className='italic'> Referencia: Plan de acción POECA, 2021</p>
                </div>
            </div>
            <div className='flex justify-center items-center w-full'>
                <img src="/atmosfericos1.png" alt="Atmosfericos Resumen" className="" />
            </div>
            <br/>
        </main>
    );
};

export default AnalistEvent;
