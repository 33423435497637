import React, { useState } from 'react';

interface Video {
    src: string;
    title: string;
    style?: string;
}

interface VideoGridProps {
    videos: Video[];
}

export const VideoGrid: React.FC<VideoGridProps> = ({ videos }) => {
    const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);

    const handleVideoClick = (video: Video) => {
        setSelectedVideo(video);
    };

    const handleCloseModal = () => {
        setSelectedVideo(null);
    };

    const getYouTubeVideoId = (url: string): string | null => {
        const regExp = /^.*(youtu.be\/|v\/|\/u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);
        return match && match[2].length === 11 ? match[2] : null;
    };

    return (
        <div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-4">
                {videos.map((video, index) => (
                    <div
                        key={index}
                        className="border border-gray-300 rounded-lg shadow-md overflow-hidden cursor-pointer"
                        onClick={() => handleVideoClick(video)}
                    >
                        <img
                            src={`https://img.youtube.com/vi/${getYouTubeVideoId(video.src)}/0.jpg`}
                            alt={`Thumbnail for ${video.title}`}
                            className="w-full h-auto"
                        />
                        <div
                            className={`bg-primary text-textColor p-2 text-center ${video.style || ''}`}>                            
                        {video.title}
                        </div>
                    </div>
                ))}
            </div>

            {selectedVideo && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
                    <div className="bg-white p-4 rounded-lg relative w-full max-w-3xl">
                        <button
                            className="absolute top-2 right-2 text-textColor bg-red-600 rounded-full p-2"
                            onClick={handleCloseModal}
                        >
                            X
                        </button>
                        <iframe
                            width="100%"
                            height="500"
                            src={`${selectedVideo.src}?autoplay=1`}
                            title={selectedVideo.title}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                </div>
            )}
        </div>
    );
};
