import React from 'react';

const ContactPague: React.FC = () => {
    return (
        <main className="flex-grow bg-gris1 ">
        <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
            <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                <h1 className="text-3xl md:text-5xl font-bold">Contáctenos</h1>
            </div>
        </div>
        <div className="flex justify-center items-center min-h-screen py-8">
            <div className="w-full max-w-md bg-white p-8 border border-primary rounded-md shadow-lg">
            <form>
                <div className="mb-4">
                <label className="block text-sm font-semibold">Nombre</label>
                <input className="w-full p-2 border border-primary rounded-md" type="text" placeholder="Nombre" />
                </div>

                <div className="mb-4">
                <label className="block text-sm font-semibold">Correo electrónico</label>
                <input className="w-full p-2 border border-primary rounded-md" type="email" placeholder="Correo electrónico" />
                </div>

                <div className="mb-6">
                <label className="block text-sm font-semibold">Solicitud</label>
                <textarea className="w-full p-2 border border-primary rounded-md" rows={4} placeholder="Escribe tu solicitud..."></textarea>
                </div>

                <button className="w-full bg-primary text-textColor py-2 rounded-md">Enviar</button>
            </form>
            </div>
        </div>
        </main>
    );
};

export default ContactPague;
