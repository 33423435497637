import React from 'react';
import ContingenciaImg1 from './contingenciaImg1';
import ContingenciaImg2 from './contingenciaImg2';
import { useAuth } from '../Authentication';
import ReportList from './SearchPage/reportList';

const Contingencia: React.FC = () => {
    const { user } = useAuth();
    const data1 = [
        {
            name: 'Sistematización y Propuesta Pedagógica',
            href: '/Documents/contingencia/Sistematización y Propuesta pedagógica.pdf',
            type: 'P'
        },
        {
            name: 'Propuesta Pedagógica: Calidad del Aire y Salud Ambiental',
            href:'/Documents/contingencia/Propuesta Pedagógica Calidad del Aire y Salud Ambiental.pdf',
            type: 'P'
        },
        {
            name: 'Herramientas para el Apoyo al Sistema de Vigilancia',
            href: '/Documents/contingencia/Herramientas para el Apoyo al Sistema de Vigilancia.pdf',
            type: 'P'
        },
        {
            name: 'Generalidades de la Epidemiología Ambiental',
            href:'/Documents/contingencia/Generalidades de la Epidemiología Ambiental',
            type: 'P'
        },
        {
            name: 'Estudios Ecológicos de Series de Tiempo (GAM)',
            href:'/Documents/contingencia/Estudios Ecológicos de Series de Tiempo (GAM)',
            type: 'P'
        },
        {
            name: 'Morbimortalidad en Salud y Efectos Centinela relacionados a la Contaminación del Aire',
            href:'/Documents/contingencia/Morbilidad y mortalidad en Salud, Eventos Centinela Asociados a la Contaminación del Aire',
            type: 'P'
        },
        {
            name: 'Educación y Comunicación para la Salud desde la Perspectiva de la Calidad del Aire y Salud en el AMVA',
            href:'/Documents/contingencia/Educación y Comunicación para la salud, una mirada desde la calidad del aire y salud en el AMVA',
            type: 'P'
        },
        {
            name: 'Sistema de Vigilancia en Salud Ambiental con Énfasis en Calidad del Aire para el Valle de Aburrá',
            href:'/Documents/contingencia/Sistema de Vigilancia en Salud Ambiental con Énfasis en Calidad del Aire para el Valle de Aburrá',
            type: 'P'
        }
    ];
    const data2 = [
        {
            name: 'ISEE 2018',
            href:'/Documents/contingencia/ISEE 2018',
            type: 'P'
        },
        {
            name: 'ISEE 2019',
            href:'/Documents/contingencia/ISEE 2019',
            type: 'P'
        }
    ]

    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Recomendaciones de contingencias</h1>
                </div>
            </div>
            <div className="p-4 sm:p-8 text-justify text-base md:text-lg max-w-screen-lg mx-auto">
                <p className='border border-primary rounded-md p-4'>
                    Con el objetivo de guiar las acciones de cuidado de la salud que debemos adoptar los ciudadanos en cada municipio, el Área Metropolitana del Valle de Aburrá ha diseñado el Plan Operacional de Episodios de Contaminación Atmosférica. Este plan establece acciones concretas para la prevención y respuesta ante episodios de contaminación.                <br />
                <br />
                <p> 
                    Para obtener más detalles, consulte el <a href='https://www.metropol.gov.co/ambiental/calidad-del-aire/Paginas/Gestion-integral/POECA.aspx' target="_blank" rel="noopener noreferrer " className='font-bold text-primary underline'>
                        Protocolo del Plan Operacional para Enfrentar Episodios de Contaminación Atmosférica (POECA).
                    </a>
                </p>
                </p>
            </div>
            <p className='text-center font-bold text-xl text-primary'>
                Acciones individuales en episodios de contingencias 
            </p>
            <div className='flex justify-center items-center w-full'>
                <ContingenciaImg1/>
            </div>
            <div className="p-4 sm:p-8 text-justify italic text-sm md:text-base max-w-screen-lg mx-auto">
                <p className='text-center font-bold'>
                Del mismo modo que existen directrices individuales para la gestión de la calidad del aire y el cuidado de la salud, el POECA ha establecido una serie de acciones colectivas. Entre estas destacan las siguientes:                </p>
            </div>
            <p className='text-center font-bold text-xl  text-primary'>
                Acciones colectivas en episodios de contingencias
            </p>
            <div className='flex justify-center items-center w-full'>
                <ContingenciaImg2/>
            </div>
            <br />
            {user && user.features.rol === 'Técnico' && (
                <div>
                    <div>
                        <div className="p-8">
                            <ReportList reports={data1} title='Plan para el Fortalecimiento de Capacidades Institucionales' />
                            <ReportList reports={data2} title='Ponencias y Presentaciones en Eventos Académicos' />
                        </div>
                    </div>
                    <h3 className="text-primary font-bold text-center">Aliados</h3>
                    <br/>
                    <div className="flex justify-center items-center text-center p-4">
                        <a href="https://siata.gov.co/siata_nuevo/" target="_blank" rel="noopener noreferrer">
                            <img src="/SIATA.png" alt="Logo SIATA" className="mx-auto" />
                        </a>
                    </div>
                </div>
            )}
        </main>
    );
};

export default Contingencia;
