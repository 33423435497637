import React from 'react';

const NormatividadNacional: React.FC = () => {
    return (
            <div className="overflow-x-auto p-2 sm:p-8 mx-auto">
                <table className="w-full border border-collapse">
                    <thead>
                        <tr>
                            <th colSpan={3}  className="bg-primary text-textColor py-4 text-center">
                                Normatividad Nacional
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={3}  className="bg-gris2 py-4 text-center">
                                Medio Ambiente
                            </th>
                        </tr>
                        <tr className="bg-gris1">
                            <th className="border px-4 py-2 text-center">
                                Norma
                            </th>
                            <th className="border px-4 py-2 text-center">
                                Institución
                            </th>
                            <th className="border px-4 py-2 text-center">
                                Descripción
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className="border px-4 py-2 text-center">
                                <a href="http://www.ideam.gov.co/documents/51310/527621/Decreto+948+de+1995.pdf/670a0603-4d1f-454f-941e-08e6ba70666d" target="_blank" className="text-blue-600 underline">
                                    Reglamento de Protección y Control de la Calidad de Aire
                                </a>
                                <p>(Decreto 948 de 1995)</p>
                            </td>
                            <td className="border px-4 py-2 text-center">
                                Ministerio del Medio Ambiente
                            </td>
                            <td className="border px-4 py-2">
                                <p className="ml-4">Se establecen las normas y principios generales para la protección atmosférica, los mecanismos de prevención, control y atención de episodios por contaminación del aire, generada por fuentes contaminantes fijas y móviles.</p>
                            </td>
                        </tr>
                        <tr className="bg-gris1">
                            <td className="border px-4 py-2 text-center">
                                <a href="https://colaboracion.dnp.gov.co/CDT/Conpes/Económicos/3344.pdf" target="_blank" className="text-blue-600 underline">
                                    Lineamientos para la formulación de la política de prevención y control de la contaminación del aire
                                </a>
                                <p>(CONPES 3344/ 2005)</p>
                            </td>
                            <td className="border px-4 py-2 text-center">
                                Consejo Nacional de Política Económica y Social - CONPES
                            </td>
                            <td className="border px-4 py-2">
                                <p className="ml-4">a) Programa de fortalecimiento institucional en materia de prevención, control y monitoreo de la contaminación del aire.</p>
                                <p className="ml-4">b) Programa de monitoreo y seguimiento del aire a nivel nacional, regional y local a través del desarrollo de un protocolo de monitoreo y seguimiento.</p>
                                <p className="ml-4">c) Articulación de la información de los sectores de ambiente, energía, transporte y salud, que permita mejorar el conocimiento y orientar las investigaciones sobre el origen de la contaminación atmosférica y su impacto sobre la salud humana.</p>
                                <p className="ml-4">d) Identificación de necesidades de información, debilidades y requerimientos técnicos para el fortalecimiento de la vigilancia epidemiológica asociada con la contaminación del aire.</p>
                            </td>
                        </tr>
                        <tr className="bg-gris1">
                            <td className="border px-4 py-2 text-center">
                                <a href="https://www.funcionpublica.gov.co/eva/gestornormativo/norma_pdf.php?i=18926" target="_blank" className="text-blue-600 underline">
                                Comisión Técnica Nacional Intersectorial para la Prevención y el Control de la Contaminación del Aire, Conaire.&nbsp;
                                </a>
                                <p>Decreto 244 de 2006</p>
                            </td>
                            <td className="border px-4 py-2 text-center">
                                República de Colombia
                            </td>
                            <td className="border px-4 py-2">
                                <p className="ml-4">Tiene como objeto asegurar la coordinación intersectorial a nivel público de las políticas, planes y programas de carácter nacional, para prevenir y controlar la contaminación del aire.</p>
                            </td>
                        </tr>
                        <tr className="bg-gris1">
                            <td className="border px-4 py-2 text-center">
                                <a href="" target="_blank" className="text-blue-600 underline">
                                Norma de Calidad del Aire o Nivel de Inmisión, para todo el territorio nacional en condiciones de referencia.
                                </a>
                                <p>Resolución 601 de 2006 </p>
                            </td>
                            <td className="border px-4 py-2 text-center">
                                Ministerio de Ambiente, Vivienda y Desarrollo Territorial
                            </td>
                            <td className="border px-4 py-2">
                                <p className="ml-4"> Norma de calidad del aire o nivel de inmisión, con el propósito de garantizar un ambiente sano y minimizar los riesgos sobre la salud humana que puedan ser causados por la concentración de contaminantes en el aire ambiente.</p>
                            </td>
                        </tr>
                        <tr className="bg-gris1">
                            <td className="border px-4 py-2 text-center">
                                <a href="https://www.ins.gov.co/Normatividad/Resoluciones/RESOLUCIÓN%200650%20DE%202010.pdf" target="_blank" className="text-blue-600 underline">
                                Protocolo para el Monitoreo y Seguimiento de la Calidad del Aire
                                </a>
                                <p>Resolución 650 de 2010</p>
                            </td>
                            <td className="border px-4 py-2 text-center">
                            Ministerio de Ambiente, Vivienda y Desarrollo Territorial
                            </td>
                            <td className="border px-4 py-2">
                                <p className="ml-4">El protocolo establece las directrices, metodologías y procedimientos necesarios para llevar a cabo las actividades de monitoreo y seguimiento de la calidad del aire en el territorio nacional.</p>
                                <p className="ml-4">Se modifica por la resolución 2154 de 2010.</p>
                            </td>
                        </tr>
                        <tr className="bg-gris1">
                            <td className="border px-4 py-2 text-center">
                                <a href="http://sisaire.ideam.gov.co/ideam-sisaire-web/informacion.xhtml?de=normatividad" target="_blank" className="text-blue-600 underline">
                                Subsistema de Información sobre Calidad del Aire -SISAIRE.
                                </a>
                                <p>Resolución 651 de 2010</p>
                            </td>
                            <td className="border px-4 py-2 text-center">
                            Ministerio de Ambiente, Vivienda y Desarrollo Territorial
                            </td>
                            <td className="border px-4 py-2">
                                <p className="ml-4"> Subsistema de Información sobre Calidad del Aire – SISAIRE como fuente principal de información para el diseño, evaluación y ajuste de las políticas y estrategias nacionales y regionales de prevención y control de la contaminación del aire.</p>
                            </td>
                        </tr>
                        <tr className="bg-gris1">
                            <td className="border px-4 py-2 text-center">
                                <a href="http://www.ideam.gov.co/documents/51310/527391/2.+Resolución+2254+de+2017+-+Niveles+Calidad+del+Aire..pdf/c22a285e-058e-42b6-aa88-2745fafad39f" target="_blank" className="text-blue-600 underline">
                                Norma de la calidad del aire ambiente
                                </a>
                                <p>Resolución 2254 de 2017</p>
                            </td>
                            <td className="border px-4 py-2 text-center">
                            Ministerio de Ambiente y Desarrollo Sostenible
                            </td>
                            <td className="border px-4 py-2">
                                <p className="ml-4">Niveles máximos permisibles de contaminantes criterio, la declaratoria de los niveles de prevención, alerta o emergencia, el Índice de calidad del aire, y otras disposiciones para la gestión del recurso aire en todo el territorio, con el objeto de garantizar un ambiente sano y minimizar el riesgo a la salud humana.</p>
                            </td>
                        </tr>
                        <tr className="bg-gris1">
                            <td className="border px-4 py-2 text-center">
                                <a href="https://colaboracion.dnp.gov.co/CDT/Conpes/Económicos/3943.pdf" target="_blank" className="text-blue-600 underline">
                                Política para el mejoramiento de la calidad del aire
                                </a>
                                <p>CONPES 3943/2018</p>
                            </td>
                            <td className="border px-4 py-2 text-center">
                            Consejo Nacional de Política Económica y Social República de Colombia - CONPES
                            </td>
                            <td className="border px-4 py-2">
                                <p className="ml-4">Acciones para reducir las concentraciones de contaminantes en el aire a través de la renovación y modernización del parque automotor, la reducción del contenido de azufre en los combustibles, la implementación de mejores técnicas y prácticas en la industria, la optimización de la gestión de la información, el desarrollo de la investigación, el ordenamiento del territorio y la gestión del riesgo por contaminación del aire</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    );
};

export default NormatividadNacional;
