
const ContingenciaImg1 = () => {
    return (
        <div className="border-t-2 border-primary  p-4 max-w-screen-lg mx-auto space-y-4">
        {/* Nivel I */}
        <div className="flex space-x-4 border border-primary rounded-lg">
            <div className="min-w-52 bg-primary text-white p-2 rounded-md flex items-center justify-center">
            <div className="text-center font-bold">
                <p>Nivel I:</p>
                <p>Sin riesgo</p>
                <p>0-12 µg/m³</p>
            </div>
            </div>
            <div>
            <ul className="list-disc pl-5 mt-4 mb-4">
                <li>Se puede realizar cualquier actividad al aire libre.</li>
                <li>Incrementar las medidas de higiene personal, como el lavado de manos.</li>
            </ul>
            </div>
        </div>

        {/* Nivel II */}
        <div className="flex space-x-4 border border-primary rounded-lg">
            <div className="min-w-52 bg-yellow-400 text-white p-2 rounded-md flex items-center justify-center">
            <div className="text-center font-bold">
                <p>Nivel II:</p>
                <p>Moderado</p>
                <p>13-37 µg/m³</p>
            </div>
            </div>
            <div>
            <ul className="list-disc pl-5 mt-8 mb-8">
                <li>Las personas vulnerables deben considerar limitar los esfuerzos prolongados al aire libre.</li>
            </ul>
            </div>
        </div>

        {/* Nivel III */}
        <div className="flex space-x-4 border border-primary rounded-lg">
            <div className="w-52 bg-analist text-white p-2 rounded-md flex items-center justify-center sm: min-w-52">
            <div className="text-center font-bold">
                <p>Nivel III:</p>
                <p>Dañino para grupos sensibles</p>
                <p>38-55 µg/m³</p>
            </div>
            </div>
            <div>
            <ul className="list-disc pl-5">
                <li>Utilizar el Sistema Integrado de Transporte del Valle de Aburrá (SITVA).</li>
                <li>Compartir el vehículo con otras personas.</li>
                <li>Evitar el uso de vehículos particulares y motocicletas para trayectos cortos.</li>
                <li>Realizar revisiones técnicas al vehículo de manera periódica, varias veces al año.</li>
                <li>Utilizar paraderos de buses autorizados para no obligar a los conductores a detenerse en cualquier sitio.</li>
                <li>Planear el recorrido para hacer varias actividades en un solo viaje.</li>
                <li>Hacer uso del teletrabajo.</li>
                <li>Realizar actividad física en lugares cerrados, como coliseos.</li>
            </ul>
            </div>
        </div>

        {/* Nivel IV */}
        <div className="flex space-x-4 border border-primary rounded-lg">
            <div className="min-w-52 bg-red-500 text-white p-2 rounded-md flex items-center justify-center">
            <div className="text-center font-bold">
                <p>Nivel IV:</p>
                <p>Dañino para la salud</p>
                <p>56-150 µg/m³</p>
            </div>
            </div>
            <div>
            <ul className="list-disc pl-5">
                <li>Utilizar el Sistema Integrado de Transporte del Valle de Aburrá (SITVA).</li>
                <li>Compartir el vehículo con otras personas.</li>
                <li>Evitar el uso de vehículos particulares y motocicletas para trayectos cortos.</li>
                <li>Utilizar paraderos de buses autorizados para no obligar a los conductores a detenerse en cualquier sitio.</li>
                <li>Planear el recorrido para hacer varias actividades en un solo viaje.</li>
                <li>Hacer uso del teletrabajo.</li>
                <li>Realizar actividad física en lugares cerrados, como coliseos.</li>
            </ul>
            </div>
        </div>

        {/* Nivel V */}
        <div className="flex space-x-4 border border-primary rounded-lg">
            <div className="min-w-52 bg-purple-500 text-white p-2 rounded-md flex flex-col items-center justify-center">
            <div className="text-center font-bold">
                <p>Nivel V:</p>
                <p>Muy dañino para la salud</p>
                <p>≥ 151 µg/m³</p>
            </div>
            </div>
            <div>
            <ul className="list-disc pl-5 mt-8 mb-8">
                <li>La población en general debe suspender los esfuerzos al aire libre.</li>
            </ul>
            </div>
        </div>
        </div>
    );
};

export default ContingenciaImg1;
