import React from 'react';

const NormatividadNacionalSalud: React.FC = () => {
    return (
            <div className="overflow-x-auto p-2 sm:p-8 mx-auto">
                <table className="w-full border border-collapse">
                <thead>
                        <tr>
                            <th colSpan={3}  className="bg-primary text-textColor py-4 text-center">
                                Normatividad Nacional
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={3}  className="bg-gris2 py-4 text-center">
                                Salud
                            </th>
                        </tr>
                        <tr className="bg-gris1">
                            <th className="border px-4 py-2 text-center">
                                Norma
                            </th>
                            <th className="border px-4 py-2 text-center">
                                Institución
                            </th>
                            <th className="border px-4 py-2 text-center">
                                Descripción
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-gris1">
                                <td className="border px-4 py-2 text-center">
                                    <a href="https://www.mineducacion.gov.co/1621/articles-86098_archivo_pdf.pdf" target="_blank" className="text-blue-600 underline">
                                    Normas orgánicas en materia de recursos y competencias territoriales</a>
                                    <p> Ley 715 de 2001</p>
                                </td>
                                <td className="border px-4 py-2 text-center">El Congreso de Colombia</td>
                                <td className="border px-4 py-2">
                                    <p className="ml-4">Competencias en el sector salud. Competencias de las entidades territoriales.</p>
                                </td>
                        </tr>
                        <tr className="bg-gris1">
                            <td className="border px-4 py-2 text-center">
                                <a href="https://www.minsalud.gov.co/sites/rid/Lists/BibliotecaDigital/RIDE/DE/DIJ/Decreto-3518-de-2006.pdf" target="_blank" className="text-blue-600 underline">
                                Reglamentación del Sistema de Vigilancia en Salud Pública –SIVIGILA
                                </a>
                                <p>Decreto 3518 de 2006</p>
                            </td>
                            <td className="border px-4 py-2 text-center">República de Colombia</td>
                            <td className="border px-4 py-2">
                                <p className="ml-4">Provisión en forma sistemática y oportuna, de información sobre la dinámica de los efectos que afecten o puedan afectar la salud de la población.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    );
}
export default NormatividadNacionalSalud;
