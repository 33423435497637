import React, { useEffect, useState } from 'react'; 
import { Link } from 'react-router-dom';
import { HidenIcon } from '../Utils/Icons';
import { useMenuStore } from '../Store/menuStore';
import { useAuth } from './Authentication';

const Header: React.FC = () => {
    const { isOpen, setIsOpen } = useMenuStore();
    const [isEventosOpen, setIsEventosOpen] = useState(false);
    const [isAccionesOpen, setIsAccionesOpen] = useState(false);
    const [isAyudaOpen, setIsAyudaOpen] = useState(false);
    const [isDocumentosOpen, setIsDocumentosOpen] = useState(false);
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const { user, logout } = useAuth();

    useEffect(() => {
    }, [user]);

    const closeAllMenus = () => {
        setIsEventosOpen(false);
        setIsAccionesOpen(false);
        setIsAyudaOpen(false);
        setIsDocumentosOpen(false);
        setIsLoginOpen(false);
    };

    const toggleEventosMenu = () => {
        closeAllMenus();
        setIsEventosOpen(!isEventosOpen);
    };

    const toggleAccionesMenu = () => {
        closeAllMenus();
        setIsAccionesOpen(!isAccionesOpen);
    };

    const toggleAyudaMenu = () => {
        closeAllMenus();
        setIsAyudaOpen(!isAyudaOpen);
    };

    const toggleDocumentosMenu = () => {
        closeAllMenus();
        setIsDocumentosOpen(!isDocumentosOpen);
    };

    const toggleLoginMenu = () => {
        closeAllMenus();
        setIsLoginOpen(!isLoginOpen);
    };
    const handleLogout = () => {
        logout();
        setIsLoginOpen(false);
    };

    return (
        <header className="bg-primary p-3">
            <div className="flex justify-between items-center">
                <a href="https://www.metropol.gov.co/" target="_blank" rel="noopener noreferrer">
                    <img src="logo-verde.jpg" alt="Logo Área Metropolitana" className="h-20 w-20 mr-20"
                    onError={(e) => { e.currentTarget.src = `/logo-verde.jpg?v=${new Date().getTime()}`;
                }} />
                </a>
                <div className="block lg:hidden">
                    <button onClick={() => setIsOpen(!isOpen)} className="focus:outline-none">
                        <HidenIcon className="w-6 h-6 text-primary" />
                    </button>
                </div>
                <div className={`flex-1 hidden text-base lg:flex items-center text-textColor`}>
                    <nav className="space-x-8 flex-1">
                        <Link to="/" className="hover:text-secondary focus:underline" onClick={closeAllMenus}>Inicio</Link>
                        {user && user.features.rol=='Técnico' &&(
                        <div className="relative inline-block">
                            <button onClick={toggleEventosMenu} className="hover:text-secondary focus:underline">
                                Efectos en Salud
                            </button>
                            {isEventosOpen && (
                                <div className="absolute left-0 mt-2 w-48 bg-white/80 text-black rounded-md shadow-lg z-10">
                                    <Link to="/eventos" className="block px-4 py-2 hover:bg-primary rounded-md" onClick={closeAllMenus}>Introducción</Link>
                                    <Link to="/eventos/agudos" className="block px-4 py-2 hover:bg-primary rounded-md" onClick={closeAllMenus}>Efectos Agudos</Link>
                                    <Link to="/eventos/cronicos" className="block px-4 py-2 hover:bg-primary rounded-md" onClick={closeAllMenus}>Efectos Crónicos</Link>
                                    <Link to="/analisis-atmosfericos/informacion" className="block px-4 py-2 hover:bg-primary rounded-md" onClick={closeAllMenus}>Análisis de Episodios</Link>
                                </div>
                            )}
                        </div>
                        )}
                        <div className="relative inline-block">
                            <button onClick={toggleAccionesMenu} className="hover:text-secondary focus:underline">
                                Acciones
                            </button>
                            {isAccionesOpen && (
                                <div className="absolute left-0 mt-2 w-48 bg-white/80 text-black rounded-md shadow-lg z-10">
                                    <Link to="/acciones" className="block px-4 py-2 hover:bg-primary rounded-md" onClick={closeAllMenus}>Acciones en Salud</Link>
                                    <Link to="/acciones/permanentes" className="block px-4 py-2 hover:bg-primary rounded-md" onClick={closeAllMenus}>Acciones Permanentes</Link>
                                    <Link to="/acciones/contingencia" className="block px-4 py-2 hover:bg-primary rounded-md" onClick={closeAllMenus}>Recomendaciones Contingencia</Link>
                                </div>
                            )}
                        </div>
                        
                        <Link to="/investigacion" className="hover:text-secondary focus:underline" onClick={closeAllMenus}>Investigación</Link>

                        {/* Submenú de Documentos */}
                        <div className="relative inline-block">
                            <button onClick={toggleDocumentosMenu} className="hover:text-secondary focus:underline">
                                Documentos
                            </button>
                            {isDocumentosOpen && (
                                <div className="absolute left-0 mt-2 w-48 bg-white/80 text-black rounded-md shadow-lg z-10">
                                    <Link to="/documentos" className="block px-4 py-2 hover:bg-primary rounded-md" onClick={closeAllMenus}>Documentación</Link>
                                    <Link to="/documentos/normativa" className="block px-4 py-2 hover:bg-primary rounded-md" onClick={closeAllMenus}>Normatividad</Link>
                                </div>
                            )}
                        </div>

                        {user && user.features.rol !== 'Tomador de Decisiones' && (
                        <Link to="/base-datos" className="hover:text-secondary focus:underline" onClick={() => { setIsOpen(false); closeAllMenus(); }}>Bases de datos</Link>
                        )}

                        {/* Submenú de Ayuda */}
                        <div className="relative inline-block">
                            <button onClick={toggleAyudaMenu} className="hover:text-secondary focus:underline">
                                Ayuda
                            </button>
                            {isAyudaOpen && (
                                <div className="absolute left-0 mt-2 w-48 bg-white/80 text-black rounded-md shadow-lg z-10">
                                    <Link to="/ayuda/glosario" className="block px-4 py-2 hover:bg-primary rounded-md" onClick={closeAllMenus}>Glosario</Link>
                                    {user && (<Link to="/ayuda/manual-usuario" className="block px-4 py-2 hover:bg-primary rounded-md" onClick={closeAllMenus}>Manual de Usuario</Link>)}
                                    {user && (<Link to="/ayuda/videos" className="block px-4 py-2 hover:bg-primary rounded-md" onClick={closeAllMenus}>Videos Tutoriales</Link>)}
                                </div>
                            )}
                        </div>
                    </nav>
                    <div className="ml-auto space-x-4">
                        {user ? (
                            <div className="relative inline-block">
                                <button
                                    onClick={toggleLoginMenu}
                                    className="text-white font-semibold focus:outline-none"
                                >
                                    {user.username}
                                </button>
                                {isLoginOpen && (
                                    <div className="absolute left-0 mt-2 w-48 bg-white/80 text-black rounded-md shadow-lg z-10">
                                        <Link to="/perfil" className="block px-4 py-2 hover:bg-primary rounded-md" onClick={() => setIsLoginOpen(false)}>
                                            Perfil
                                        </Link>
                                        {user && user.features.rol === 'Tomador de Decisiones' && (
                                            <Link to="/preprocesar-data" className="block px-4 py-2 hover:bg-primary rounded-md" onClick={() => setIsLoginOpen(false)}>
                                                Preprocesar Data
                                            </Link>
                                        )}
                                        {user && user.features.rol === 'Administrador' && (
                                            <Link to="/administrar-solicitudes" className="block px-4 py-2 hover:bg-primary rounded-md" onClick={() => setIsLoginOpen(false)}>
                                                Administrar Solicitudes
                                            </Link>
                                        )}
                                        <Link to="/" 
                                            onClick={handleLogout}
                                            className="block w-full text-left px-4 py-2 hover:bg-primary rounded-md"
                                        >
                                            Cerrar sesión
                                        </Link>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <>
                                <Link to="/iniciar-sesion" className="bg-white text-primary px-2 py-2 rounded">Iniciar sesión</Link>
                                <Link to="/solicitar-cuenta" className="bg-white text-primary px-2 py-2 rounded">Solicitar cuenta</Link>
                            </>
                        )}
                    </div>
                </div>

                {/* Menú móvil */}
                {isOpen && (
                    <nav className="lg:hidden absolute top-16 left-0 w-full bg-primary text-textColor flex flex-col space-y-4 p-4">
                        <Link to="/" className="hover:text-secondary focus:underline" onClick={() => { setIsOpen(false); closeAllMenus(); }}>Inicio</Link>
                        
                        {user && user.features.rol=='Técnico' &&(
                        <div className="relative">
                            <button onClick={toggleEventosMenu} className="hover:text-secondary focus:underline">
                                Efectos en Salud
                            </button>
                            {isEventosOpen && (
                                <div className="flex flex-col space-y-2 mt-2 ml-5">
                                    <Link to="/eventos" className="hover:text-secondary focus:underline" onClick={() => { setIsOpen(false); closeAllMenus(); }}>Introducción</Link>
                                    <Link to="/eventos/agudos" className="hover:text-secondary focus:underline" onClick={() => { setIsOpen(false); closeAllMenus(); }}>Efectos Agudos</Link>
                                    <Link to="/eventos/cronicos" className="hover:text-secondary focus:underline" onClick={() => { setIsOpen(false); closeAllMenus(); }}>Efectos Crónicos</Link>
                                    <Link to="/analisis-atmosfericos/informacion" className="hover:text-secondary focus:underline" onClick={() => { setIsOpen(false); closeAllMenus(); }}>Análisis de Episodios</Link>
                                </div>
                            )}
                        </div>
                        )}
                        {/* Resto del menú móvil */}
                        <div className="relative">
                            <button onClick={toggleAccionesMenu} className="hover:text-secondary focus:underline">
                                Acciones
                            </button>
                            {isAccionesOpen && (
                                <div className="flex flex-col space-y-2 mt-2 ml-5">
                                    <Link to="/acciones" className="hover:text-secondary focus:underline" onClick={() => { setIsOpen(false); closeAllMenus(); }}>Acciones en Salud</Link>
                                    <Link to="/acciones/permanentes" className="hover:text-secondary focus:underline" onClick={() => { setIsOpen(false); closeAllMenus(); }}>Acciones Permanentes</Link>
                                    <Link to="/acciones/contingencia" className="hover:text-secondary focus:underline" onClick={() => { setIsOpen(false); closeAllMenus(); }}>Recomendaciones Contingencia</Link>
                                </div>
                            )}
                        </div>

                        <Link to="/investigacion" className="hover:text-secondary focus:underline" onClick={() => { setIsOpen(false); closeAllMenus(); }}>Investigación</Link>
                        <div className="relative">
                            <button onClick={toggleDocumentosMenu} className="hover:text-secondary focus:underline">
                                Documentos
                            </button>
                            {isDocumentosOpen && (
                                <div className="flex flex-col space-y-2 mt-2 ml-5">
                                    <Link to="/documentos" className="hover:text-secondary focus:underline" onClick={() => { setIsOpen(false); closeAllMenus(); }}>Documentación</Link>
                                    <Link to="/documentos/normativa" className="hover:text-secondary focus:underline" onClick={() => { setIsOpen(false); closeAllMenus(); }}>Normatividad</Link>
                                </div>
                            )}
                        </div>
                        {user && user.features.rol !== 'Tomador de Decisiones' && (
                        <Link to="/base-datos" className="hover:text-secondary focus:underline" onClick={() => { setIsOpen(false); closeAllMenus(); }}>Bases de datos</Link>
                        )}
                        <div className="relative">
                            <button onClick={toggleAyudaMenu} className="hover:text-secondary focus:underline">
                                Ayuda
                            </button>
                            {isAyudaOpen && (
                                <div className="flex flex-col space-y-2 mt-2 ml-5">
                                    <Link to="/ayuda/glosario" className="hover:text-secondary focus:underline" onClick={() => { setIsOpen(false); closeAllMenus(); }}>Glosario</Link>
                                    {user && (<Link to="/ayuda/manual-usuario" className="hover:text-secondary focus:underline" onClick={() => { setIsOpen(false); closeAllMenus(); }}>Manual de Usuario</Link>)}
                                    {user && user.features.rol==='Técnico' && (<Link to="/ayuda/videos" className="block px-4 py-2 hover:bg-primary rounded-md" onClick={closeAllMenus}>Videos Tutoriales</Link>)}
                                </div>
                            )}
                        </div>
                        <div className="space-x-4">
                        {user ? (
                                <div className="relative">
                                    <button
                                        onClick={toggleLoginMenu}
                                        className="hover:text-secondary focus:underline text-white"
                                    >
                                        {user.username}
                                    </button>
                                    {isLoginOpen && (
                                        <div className="flex flex-col space-y-2 mt-2 ml-5">
                                            <Link
                                                to="/perfil"
                                                className="hover:text-secondary focus:underline"
                                                onClick={() => {
                                                    setIsLoginOpen(false);
                                                    setIsOpen(false);
                                                }}
                                            >
                                                Perfil
                                            </Link>
                                            {user && user.features.rol === 'Tomador de Decisiones' && (
                                                <Link to="/preprocesar-data" className="hover:text-secondary focus:underline"                                                 onClick={() => {
                                                    setIsLoginOpen(false);
                                                    setIsOpen(false);
                                                }}>
                                                    Preprocesar Data
                                                </Link>
                                            )}
                                            {user && user.features.rol === 'Administrador' && (
                                                <Link to="/administrar-solicitudes" className="hover:text-secondary focus:underline"
                                                    onClick={() => {
                                                        setIsLoginOpen(false);
                                                        setIsOpen(false);
                                                    }}
                                                >
                                                    Administrar Solicitudes
                                                </Link>
                                            )}
                                            {/* TODO: hacer vista responsive */}
                                            <Link to="/"
                                                onClick={() => {
                                                    handleLogout();
                                                    setIsLoginOpen(false);
                                                    setIsOpen(false);
                                                }}
                                                className="hover:text-secondary focus:underline"
                                            >
                                                Cerrar sesión
                                            </Link>
                                        </div>
                                    )}
                                </div>
                            ) : (
                                <>
                                    <Link
                                        to="/iniciar-sesion"
                                        className="bg-white text-primary px-2 py-2 rounded"
                                        onClick={() => setIsOpen(false)} // Cerrar el menú móvil al hacer clic
                                    >
                                        Iniciar sesión
                                    </Link>
                                    <Link
                                        to="/solicitar-cuenta"
                                        className="bg-white text-primary px-2 py-2 rounded"
                                        onClick={() => setIsOpen(false)} // Cerrar el menú móvil al hacer clic
                                    >
                                        Solicitar cuenta
                                    </Link>
                                </>
                            )}
                        </div>
                    </nav>
                )}
            </div>
        </header>
    );
};

export default Header;
