
const ContingenciaImg2 = () => {
    return (
        <div className="border-t-2 border-primary  p-4 max-w-screen-lg mx-auto space-y-4">
        {/* Nivel II */}
        <div className="flex space-x-4 border border-primary rounded-lg">
            <div className="min-w-52 bg-yellow-400 text-white p-2 rounded-md flex items-center justify-center">
            <div className="text-center font-bold">
                <p>Nivel II:</p>
                <p>Moderado</p>
                <p>13-37 µg/m³</p>
            </div>
            </div>
            <div>
            <ul className="list-disc pl-5">
                <li>Se recomienda a los establecimientos educativos reducir el esfuerzo prolongado al aire libre.</li>
                <li>Reforzar los operativos de vigilancia de posibles quemas e incendios.</li>
                <li>No hacer viajes innecesarios.</li>
                <li>Usar medios de transporte alternativos.</li>
            </ul>
            </div>
        </div>

        {/* Nivel III */}
        <div className="flex space-x-4 border border-primary rounded-lg">
            <div className="min-w-52 bg-analist text-white p-2 rounded-md flex items-center justify-center">
            <div className="text-center font-bold">
                <p>Nivel III:</p>
                <p>Dañino para grupos sensibles</p>
                <p>38-55 µg/m³</p>
            </div>
            </div>
            <div>
                <ul className="list-disc pl-5">
                    <li>Se recomienda a los establecimientos educativos que niños y adolescentes deben evitar el esfuerzo prolongado al aire libre.</li>
                    <li>Reforzar los operativos de vigilancia de posibles quemas e incendios.</li>
                    <li>No hacer viajes innecesarios.</li>
                    <li>Usar medios de transporte alternativos.</li>
                    <li>Planificar el incremento de la demanda por servicios de urgencias y consulta externa debido a enfermedades respiratorias y circulatorias, por parte de las IPS, mediante la adecuada asignación de talento humano y tecnología médica.</li>
                </ul>
            </div>
        </div>

        {/* Nivel IV */}
        <div className="flex space-x-4 border border-primary rounded-lg">
            <div className="min-w-52 bg-red-500 text-white p-2 rounded-md flex items-center justify-center">
            <div className="text-center font-bold">
                <p>Nivel IV:</p>
                <p>Dañino para la salud</p>
                <p>56-150 µg/m³</p>
            </div>
            </div>
            <div>
                <ul className="list-disc pl-5">
                    <li>Suspender obras públicas de mantenimiento urbano.</li>
                    <li>Restringir el funcionamiento de los establecimientos educativos.</li>
                    <li>Reforzar los operativos de vigilancia de posibles quemas e incendios.</li>
                    <li>No hacer viajes innecesarios.</li>
                    <li>Usar medios de transporte alternativos.</li>
                    <li>Planificar el incremento de la demanda por servicios de urgencias y consulta externa debido a enfermedades respiratorias y circulatorias, por parte de las IPS, mediante la adecuada asignación de talento humano y tecnología médica.</li>
                    <li>Diseñar procesos comunicativos dirigidos a la comunidad respecto a la calidad del aire y sus efectos en la salud, y los signos y síntomas de alerta durante la contingencia.</li>
                </ul>
            </div>
        </div>

        {/* Nivel V */}
        <div className="flex space-x-4 border border-primary rounded-lg">
            <div className="min-w-52 bg-purple-500 text-white p-2 rounded-md flex items-center justify-center">
            <div className="text-center font-bold">
                <p>Nivel V:</p>
                <p>Muy dañino para la salud</p>
                <p>≥ 151 µg/m³</p>
            </div>
            </div>
            <div>
            <ul className="list-disc pl-5 mt-8 mb-8">
                <li>La población en general debe suspender los esfuerzos al aire libre.</li>
            </ul>
            </div>
        </div>
        </div>
    );
};

export default ContingenciaImg2;
