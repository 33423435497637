import React from 'react';
import { useAuth } from '../../Authentication';

const CronicosEvent: React.FC = () => {
    const { user } = useAuth();
    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Efectos Crónicos En Salud</h1>
                </div>
            </div>
            <div className="p-4 sm:p-8 text-justify text-base md:text-lg max-w-screen-md mx-auto">
                <p className='text-xl font-bold text-center border-b-2 border-primary '>
                    ¿Cuáles son los efectos crónicos de salud relacionados a la calidad del aire?
                </p>
                <p>La exposición prolongada a la contaminación del aire está vinculada a varios problemas de salud crónicos, especialmente en el sistema respiratorio. A continuación, se detallan los principales efectos crónicos de salud relacionados:</p>
                <br/>
                <h2 className="font-bold mb-2 text-primary"> Enfermedades Respiratorias</h2>
                <div className="flex">
                    <ul className="list-disc ml-6 pl-5 p-4 space-y-2">
                        <li>Diabetes Mellitus (E10–E14)</li>
                        <li>Enfermedades Crónicas de las Vías Respiratorias Inferiores (J40–J47)</li>
                        <li>Otras Enfermedades de las Vías Respiratorias Superiores (J30–J39)</li>
                        <li>Tumores [Neoplasias] In Situ (D00–D09)</li>
                        <li>Tumores Malignos de los Órganos Respiratorios e Intratorácicos (C30–C39)</li>
                    </ul>
                </div>
                <br/>
                <p className=' text-center font-bold'>⚠️Estamos trabajando para mejorar esta sección. Regresa pronto para más actualizaciones. Disculpe las molestias.⚠️</p>
                <br/>
                <div>
                    {user && user.features.rol === 'Técnico' && (
                        <a
                            href="/Documents/Lista de eventos crónicos de enfermedades asociadas a la calidad del aire.pdf"
                            download
                            className="flex items-center justify-center text-center text-base text-blue-600 hover:underline mb-1"
                        >
                            <span className="w-2 h-2 mr-2"></span>
                            Listado de Efectos Crónicos de Enfermedades relacionados a la Calidad del Aire
                        </a>
                    )}
                </div>
            </div>
        </main>
    );
};

export default CronicosEvent;
