import React, { useState } from 'react';
import ReportList from './SearchPage/reportList';
import { useAuth } from '../Authentication';

const DataBase: React.FC = () => {
    const { user } = useAuth();

    const morbilidad = [
        {
            name: 'Enfermedades relacionados a la contaminación del aire para todos los municipios 2008-2017',
            href: '/Documents/basededatos/Enfermedades asociadas a la contaminación del aire para todos los municipios 2008-2017.xlsx',
            type: 'E'}
    ];
    const defunciones = [
        {
            name: 'Defunciones según causas relacionados a la contaminación del aire para todos los municipios 2008-2017',
            href: '/Documents/basededatos/Defunciones según causas asociadas a la contaminación del aire para todos los municipios 2008-2017.xlsx',
            type: 'E'
        },
        {
            name: 'Tasas de Mortalidad de enfermedad Respiratorias y Circulatorias por sexo para todos los municipios',
            href: '/Documents/basededatos/Tasas de Mortalidad de enfermedad Respiratorias y Circulatorias por sexo para todos los municipios.xlsx',
            type: 'E'
        },
    ];
    const riesgo = [
        {
            name: 'Resultados del Análisis de Riesgo para cada Municipio 2008 - 2016',
            href: '/Documents/basededatos/Resultados del Análisis de Riesgo para cada Municipio 2008 - 2016.xlsx',
            type: 'E'
        },
    ];
    const morbilidadTecnico = [
        {
            name: 'Enfermedades relacionados a la Contaminación del Aire para el Municipio de Barbosa 2008-2017',
            href: '/Documents/basededatos/Barbosa.xlsx',
            type: 'E'
        },
        {
            name: 'Enfermedades relacionados a la Contaminación del Aire para el Municipio de Bello 2008-2017',
            href: '/Documents/basededatos/Bello.xlsx',
            type: 'E'
        },
        {
            name: 'Enfermedades relacionados a la Contaminación del Aire para el Municipio de Caldas 2008-2017',
            href: '/Documents/basededatos/Caldas.xlsx',
            type: 'E'
        },
        {
            name: 'Enfermedades relacionados a la Contaminación del Aire para el Municipio de Copacabana 2008-2017',
            href: '/Documents/basededatos/Copacabana.xlsx',
            type: 'E'
        },
        {
            name: 'Enfermedades relacionados a la Contaminación del Aire para el Municipio de Envigado 2008-2017',
            href: '/Documents/basededatos/Envigado.xlsx',
            type: 'E'
        },
        {
            name: 'Enfermedades relacionados a la Contaminación del Aire para el Municipio de Girardota 2008-2017',
            href: '/Documents/basededatos/Girardota.xlsx',
            type: 'E'
        },
        {
            name: 'Enfermedades relacionados a la Contaminación del Aire para el Municipio de Itagui 2008-2017',
            href: '/Documents/basededatos/Itagui.xlsx',
            type: 'E'
        },
        {
            name: 'Enfermedades relacionados a la Contaminación del Aire para el Municipio de La Estrella 2008-2017',
            href: '/Documents/basededatos/LaEstrella.xlsx',
            type: 'E'
        },
        {
            name: 'Enfermedades relacionados a la Contaminación del Aire para el Municipio de Medellin por Grupo Parte 1',
            href: '/Documents/basededatos/',
            type: 'E'
        },
        {
            name: 'Enfermedades relacionados a la Contaminación del Aire para el Municipio de Medellin por Grupo Parte 2',
            href: '/Documents/basededatos/',
            type: 'E'
        },
        {
            name: 'Enfermedades relacionados a la Contaminación del Aire para el Municipio de Sabaneta 2008-2017',
            href: '/Documents/basededatos/Sabaneta.xlsx',
            type: 'E'
        },
        {
            name: 'Diccionario conteo diario de las enfermedades relacionados a la contaminación del aire para todos los municipios 2008-2017',
            href: '/Documents/basededatos/Diccionario conteo diario de las enfermedades asociadas a la contaminación del aire para todos los municipios 2008-2017.xlsx',
            type: 'E'
        }
    ];
    
    const defuncionesTecnico = [
        {
            name: 'Defunciones según causas relacionados a la contaminación del aire para todos los municipios 2008-2017',
            href: '/Documents/basededatos/Defunciones según causas asociadas a la contaminación del aire para todos los municipios  2008-2017',
            type: 'E'
        },
        {
            name: 'Tasas de Mortalidad de Enfermedades Respiratorias y Circulatorias por sexo para todos los municipios',
            href: '/Documents/basededatos/Tasas de Mortalidad de enfermedad Respiratorias y Circulatorias por sexo para todos los municipios.xlsx',
            type: 'E'
        },
        {
            name: 'Conteo de casos diarios de defunciones según causas relacionados a la contaminación del aire para todos los municipios 2008-2017',
            href: '/Documents/basededatos/Conteo de casos diarios de defunciones según causas asociadas a la contaminación del aire para todos los municipios 2008-2017.xlsx',
            type: 'E'
        },
        {
            name: 'Diccionario Conteo de casos diarios de defunciones según causas relacionados a la contaminación del aire para todos los municipios',
            href: '/Documents/basededatos/Diccionario Conteo de casos diarios de defunciones según causas asociadas a la contaminación del aire para todos los municipios 2008-2017.xlsx',
            type: 'E'
        }
    ];    

    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Bases de datos</h1>
                </div>
            </div>
            <div className="p-2 sm:p-4 text-justify text-base md:text-lg max-w-screen-lg mx-auto">
                <p className='border border-primary rounded-md p-4'>
                    Aquí podrá encontrar las bases de datos que han sido utilizadas para el análisis de salud y ambiente. Además de eso, un documento que detalla la metodología para el uso de las bases para todos los perfiles. 
                    <br />
                </p>
            </div>
            <div className="p-8">
                {user && user.features.rol === 'Técnico' ? (
                    <>
                        <ReportList reports={morbilidadTecnico} title='Reportes de Morbilidad' />
                        <ReportList reports={defuncionesTecnico} title='Reportes de Defunciones' />
                    </>
                ) : (
                    <>
                        <ReportList reports={morbilidad} title='Reportes de Morbilidad' />
                        <ReportList reports={defunciones} title='Reportes de Defunciones' />
                        <ReportList reports={riesgo} title='Reportes de Análisis de Riesgo' />
                    </>
                )}
            </div>
            
            <label className="block text-lg font-bold text-center"> Otras fuentes de datos</label>
            <br/>
                <div className="p-2">
                    <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                        
                        <div className={'p-3 border rounded-md border-primary'}>
                            <h3 className="text-primary font-bold text-center">Departamento Administrativo Nacional de Estadística (DANE)</h3>
                            <div className="grid grid-cols-1 gap-1 justify-center text-center">
                                <p className="text-xs "> 
                                    Es una entidad de la Rama Ejecutiva de Colombia responsable de la planificación, recolección, análisis y difusión de estadísticas oficiales con altos estándares de calidad. Con más de 50 años de experiencia, realiza más de 30 investigaciones en áreas como economía, industria, población, sector agropecuario y calidad de vida, brindando información fundamental para el país y el mundo.                                </p>
                            </div>
                            <br/>
                            <a href="http://systema74.dane.gov.co/bincol/RpWebEngine.exe/Portal?BASE=DEFOC7907&lang=esp" target="_blank" rel="noopener noreferrer">
                                <p className='text-xs underline text-center font-bold text-primary'> De click aquí encontrar la información de nacimientos y defunciones históricas para Colombia</p>
                            </a>
                            <br/>
                            {/* <img src="DANE.png" alt="Logo DANE" className="" /> */}
                        </div>
                        <div className={'p-3 border rounded-md border-cience2'}>
                            <h3 className="text-cience2 font-bold text-center">Sistema de Alerta Temprana del Valle de Aburrá SIATA</h3>
                            <div className="grid grid-cols-1 gap-1 justify-center text-center">
                                <p className="text-xs "> 
                                Es un proyecto de ciencia, tecnología e innovación dedicado a la gestión del riesgo ambiental en la región. Monitorear y pronosticar fenómenos naturales y antrópicos, desarrollando tecnología de monitoreo en tiempo real y modelación hidrológica, meteorológica, sísmica y geotécnica. Además, proporciona información oportuna a ciudadanos, autoridades y organismos de socorro, y promueve procesos educativos y de apropiación social del conocimiento, en alineación con la Política Nacional de Gestión del Riesgo.                                </p>
                            </div>
                            <br/>
                            <a href=" http://dhime.ideam.gov.co/atencionciudadano/   " target="_blank" rel="noopener noreferrer">
                            <p className='text-xs underline text-center font-bold text-cience2'> De click aqui descargar los datos ambientales de la red de monitoreo del SIATA</p>
                            </a>
                            <br/>
                            {/* <img src="siata.png" alt="Logo SIATA" className="" /> */}
                        </div>
                        <div className={'p-3 border rounded-md border-cience'}>
                            <h3 className="text-cience font-bold text-center">Instituto de Hidrología, Meteorología y Estudios Ambientales IDEAM</h3>
                            <div className="grid grid-cols-1 gap-1 justify-center text-center">
                                <p className="text-xs "> 
                                    Es una entidad pública que apoya al Sistema Nacional Ambiental, mediante la generación de conocimiento y la producción de información confiable sobre los recursos naturales y el medio ambiente. Su labor facilita la definición de políticas ambientales y apoya la toma de decisiones en los sectores público, privado y por parte de la ciudadanía.                                </p>
                            </div>
                            <br/>
                            <a href="https://siata.gov.co/descarga_siata/index.php/index2/login/cerrar_sesion" target="_blank" rel="noopener noreferrer">
                                <p className='text-xs text-center font-bold underline text-cience'> De click para descargar y consultar los datos hidrometeorológicos</p>
                            </a>
                            {/* <img src="Ideam.jpg" alt="Logo IDEAM" className="" /> */}
                        </div>
                    </div>
                </div>
                <br/>
        </main>
    );
};

export default DataBase;