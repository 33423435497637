import React from 'react';

const InformationEvent: React.FC = () => {
    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Efectos en salud analizados en SIVISA</h1>
                </div>
            </div>
            <div className="p-4 mx-auto">
                <div className="grid grid-cols-1 md:grid-cols-3 border border-primary rounded-md border-t-4">
                    <div className="col-span-1 md:col-span-1 p-8">
                        <p className="text-xl lg:text-2xl font-bold text-primary mb-2">
                            ¿Qué es un evento de salud asociado a la calidad del aire?
                        </p>
                        <p className="text-base lg:text-lg">
                            Los Efectos en Salud relacionados a la calidad del aire incluyen patologías que, según evidencias científicas, pueden verse afectadas por variaciones en los contaminantes críticos del aire (PM10, PM2.5, ozono). En el caso de SIVISA, se priorizan enfermedades agudas del sistema respiratorio y circulatorio, y las muertes prematuras por estas causas. El seguimiento de estos efectos ayuda a evaluar la efectividad de las acciones para mejorar la calidad del aire y detectar aumentos en el riesgo o la frecuencia de dichos efectos en los municipios.
                        </p>
                    </div>
                    <div className="col-span-1 md:col-span-2 flex justify-center items-center">
                        <img src="/AfeccionesSalud.png" alt="Afecciones1" className="w-full md:w-3/4 lg:w-full" />
                    </div>
                </div>
            </div>

            <div className="p-4 mx-auto">
                <div className="border border-primary rounded-md border-t-4">
                    <p className='text-xl font-bold text-center text-primary p-2'>
                        Fuentes de información de los Efectos en Salud agudos relacionados a  la calidad del aire
                    </p>
                    <p className='justify-center items-center p-2 text-center'>
                    El SIVISA utiliza los sistemas de registro disponibles en el territorio para obtener datos sobre enfermos, defunciones, concentraciones de contaminantes y condiciones meteorológicas.</p>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-justify p-4">
                        <p className='border border-primary p-4 rounded-md'>
                        La identificación de los enfermos se realiza mediante los Registros Individuales de Prestación de Servicios de Salud (RIPS), que recopilan información sobre consultas, urgencias, hospitalizaciones y otros procedimientos en los municipios del Valle de Aburrá.
                        </p>
                        <p className='border border-primary p-4 rounded-md'>
                        Se dispone de datos del Sistema de Vigilancia en Salud Pública (SIVIGILA) sobre efectos prioritarios, como las Infecciones Respiratorias Agudas en menores de 5 años.
                        </p>
                        <p className='border border-primary p-4 rounded-md'>
                        Para analizar defunciones, se emplea el Registro Único de Afiliados-RUAF y los datos del DANE, almacenados en el Sistema de Información de la Protección Social (SISPRO).
                        </p>
                    </div>
                    <p className='text-xl font-semibold text-center text-primary'> Procesamiento de datos en salud y ambiente</p>
                    <br/>
                    <img src="/EventoSalud1.png" alt="EventosSalud" className="w-full md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto" />
                </div>
                <br/>
                <div className="border border-primary rounded-md border-t-4">
                    <p className='text-xl font-bold text-center text-primary p-2'>
                        Población Vulnerable
                    </p>
                    <img src="/EventoSalud2.png" alt="EventosSalud2" className="w-full md:max-w-md lg:max-w-lg xl:max-w-xl mx-auto" />
                </div>
            </div>
            <br/>
        </main>
    );
};

export default InformationEvent;
