import React, { useRef } from 'react';

const VideoManual: React.FC = () => {
    const videoRef = useRef<HTMLVideoElement>(null);

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    };
    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Videos Tutoriales</h1>
                </div>
            </div>
            <div className="flex justify-center items-center h-screen">
                <div className="border border-primary rounded-lg p-6 max-w-xl w-full text-center bg-textColor">
                    <div onClick={handlePlayPause} className="cursor-pointer">
                    <p className="font-bold text-primary mb-4">Video explicación de resultados GAM</p>
                    <video
                        className="w-full h-auto rounded-lg shadow-lg"
                        src={'/Explicacion resultados.mp4'}
                        controls
                    >
                        Tu navegador no soporta el elemento de video.
                    </video>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default VideoManual;
