import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth, Role } from '../../../Authentication';

// Configuración para los diferentes roles
const roleConfig: Partial<Record<Role, { title: string; reports: string[] }>> = {
    default: {
        title: "Resumen de defunciones relacionados a la contaminación del aire en el período 2008 a 2019",
        reports: [
            "https://app.powerbi.com/view?r=eyJrIjoiMzJmODEwMmYtNmI5OS00NDFhLTk5NTAtZDg2ZWE3Y2E0ODZhIiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9",
            "https://app.powerbi.com/view?r=eyJrIjoiNGFkMTY1YWItMTNiMy00OWY1LThkYzAtYjIxMWJmYzczZjZmIiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9&pageName=ReportSection",
            "https://app.powerbi.com/view?r=eyJrIjoiZWY2Y2RhMGEtNGQ5Ny00NTRkLWEzYTEtZmQ5OWMwZTFjOGIzIiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9",
            "https://app.powerbi.com/view?r=eyJrIjoiZWRiMjU2ZDctZDlkMy00YzQ1LWEzMWMtNzdlNmJmOTJmZDFkIiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9",
            "https://app.powerbi.com/view?r=eyJrIjoiMjdhZDRjMjQtNzFmMC00ZjVmLWExMTUtZTM0NmM1YjNmNWUyIiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9"
        ]
    },
    Técnico: {
        title: "Tasa ajustada de mortalidad por 100.000 habitantes para las enfermedades relacionados a la calidad del aire por municipio",
        reports: [
            "https://app.powerbi.com/view?r=eyJrIjoiMTNhMDAxYjItNTQ5Ny00YTZhLWEyNTctMmQ2YzYyMmQ0ZGI3IiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9",
            "https://app.powerbi.com/view?r=eyJrIjoiOTVmMGVhZTktYjFiNi00MDI2LWE1MTktN2NlMDIwMzYyZDRhIiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9",
            "https://app.powerbi.com/view?r=eyJrIjoiODZkNTFmM2UtYWE2Mi00ZmU1LTliNTYtOGM0ODU0OTA4NzgxIiwidCI6IjRkZWI0ZjAwLTNhOTgtNDcwMi04Nzk2LTIxNmRiMDljMzA3YyIsImMiOjR9",
        ]
    }
};

const getRoleConfig = (role: Role): { title: string; reports: string[] } => {
    return roleConfig[role] || roleConfig.default!;
};

const Mortalidad: React.FC = () => {
    const { user } = useAuth();
    const { title, reports } = getRoleConfig(user?.features.rol || 'default');

    return (
        <main className="flex-grow bg-gris1">
            <div className="bg-cover bg-center h-48" style={{ backgroundImage: 'url("/fondo.png")' }}>
                <div className="mx-auto p-14 justify-center items-center text-center text-textColor">
                    <h1 className="text-3xl md:text-5xl font-bold">Mortalidad</h1>
                    <br />
                    <div>
                        <Link to="/eventos/agudos" className="px-4 py-2 hover:bg-primary rounded-md">Volver</Link>
                    </div>
                </div>
            </div>
            <div className="p-4 sm:p-8 text-justify text-base md:text-lg max-w-screen-lg mx-auto">
                <div className="border border-primary rounded-md p-4">
                    <h2 className="font-bold text-lg text-center">¿Cuál ha sido el comportamiento de los casos de mortalidad por enfermedades relacionadas con la contaminación del aire en los municipios del Valle de Aburrá?</h2>
                    <p className="mt-2">La mortalidad por enfermedades agudas está relacionada con la calidad del aire. En este reporte, puedes explorar el comportamiento de la mortalidad aguda, tanto respiratoria como circulatoria, en la población de los municipios del Valle de Aburrá entre los años 2008 y 2019.</p>
                </div>
            </div>
            <h2 className="font-bold text-primary text-lg text-center mb-4">{title}</h2>

            {reports.map((reportSrc, index) => (
                    <div key={index} className="w-full h-screen mb-20">
                        <iframe
                            allowFullScreen={true}
                            src={reportSrc}
                            title={`Report ${index + 1}`}
                            className="w-full h-full border border-gray-300 rounded-md"
                        ></iframe>
                        <div className="flex justify-center mt-4 space-x-4">
                            <button onClick={() => window.location.reload()} className="bg-primary text-textColor px-4 py-2 rounded-md">Limpiar Consulta</button>
                            <button onClick={() => window.print()} className="bg-primary text-textColor px-4 py-2 rounded-md">Imprimir/Guardar PDF</button>
                        </div>
                    </div>
            ))}
        </main>
    );
};

export default Mortalidad;
