import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import MainPage from './Routers/Pages/mainPage';
import Login from './Routers/Pages/login';
import Header from './Routers/Header';
import Footer from './Routers/Footer';
import UserRequest from './Routers/Pages/userRequest';
import Search from './Routers/Pages/SearchPage/search';
import AccionesSalud from './Routers/Pages/accionesSalud';
import AccionesPermanentes from './Routers/Pages/accionesPermanentes';
import Contingencia from './Routers/Pages/contingencia';
import DataBase from './Routers/Pages/dataBase';
import ContactPague from './Routers/Pages/contact';
import Glossary from './Routers/Pages/glossary';
import UserManual from './Routers/Pages/userManual';
import DocumentsPague from './Routers/Pages/documents';
import Normativa from './Routers/Pages/normative';
import InformationEvent from './Routers/Pages/Events/information';
import AgudosEvent from './Routers/Pages/Events/agudos';
import CronicosEvent from './Routers/Pages/Events/cronicos';
import Mortalidad from './Routers/Pages/Events/Agudos/mortalidad';
import Morbilidad from './Routers/Pages/Events/Agudos/morbilidad';
import AnalisisRiesgos from './Routers/Pages/Events/Agudos/analisisRiesgos';
import AnalistEvent from './Routers/Pages/Episodios Atmosfericos/analisis';
import E2015 from './Routers/Pages/Episodios Atmosfericos/e-2015';
import E2016 from './Routers/Pages/Episodios Atmosfericos/e-2016';
import E2017 from './Routers/Pages/Episodios Atmosfericos/e-2017';
import E2018 from './Routers/Pages/Episodios Atmosfericos/e-2018';
import E2019 from './Routers/Pages/Episodios Atmosfericos/e-2019';
import PerfilPague from './Routers/Pages/perfilPague';
import { AuthProvider } from './Routers/Authentication';
import PreprocessPague from './Routers/Pages/preprocessPague';
import UserRequestPague from './Routers/Pages/adminUserRequest';
import VideoManual from './Routers/Pages/userVideos';

const App: React.FC = () => {
  return (
    <AuthProvider>
    <Router>
      <div className="min-h-screen flex flex-col">
        <Header/>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/iniciar-sesion" element={<Login />} />
          <Route path="/solicitar-cuenta" element={<UserRequest/>} />
          <Route path="/eventos" element={<InformationEvent/>}/>
          <Route path="/eventos/agudos" element={<AgudosEvent/>}/>
          <Route path="/eventos/agudos/morbilidad" element={<Morbilidad/>}/>
          <Route path="/eventos/agudos/mortalidad" element={<Mortalidad/>}/>
          <Route path="/eventos/agudos/analisis-riesgos" element={<AnalisisRiesgos/>}/>
          <Route path="/eventos/cronicos" element={<CronicosEvent/>}/>
          <Route path="/acciones" element={<AccionesSalud />} />
          <Route path="/acciones/permanentes" element={<AccionesPermanentes />} />
          <Route path="/acciones/contingencia" element={<Contingencia />} />
          <Route path="/investigacion" element={<Search/>}/>
          <Route path="/analisis-atmosfericos/informacion" element={<AnalistEvent/>} />
          <Route path="/analisis-atmosfericos/episodio-2015" element={<E2015/>}/>
          <Route path="/analisis-atmosfericos/episodio-2016" element={<E2016/>}/>
          <Route path="/analisis-atmosfericos/episodio-2017" element={<E2017/>}/>
          <Route path="/analisis-atmosfericos/episodio-2018" element={<E2018/>}/>
          <Route path="/analisis-atmosfericos/episodio-2019" element={<E2019/>}/>
          <Route path="/documentos" element={<DocumentsPague/>}/>
          <Route path="/documentos/normativa" element={<Normativa/>} />
          <Route path="/base-datos" element={<DataBase/>}/>
          <Route path="/ayuda/glosario" element={<Glossary/>}/>
          <Route path="/ayuda/manual-usuario" element={<UserManual/>} />
          <Route path='/ayuda/videos' element={<VideoManual/>} />
          <Route path="/contacto" element={<ContactPague/>}/>
          <Route path="/perfil" element={<PerfilPague/>}/>
          <Route path="/preprocesar-data" element={<PreprocessPague/>}/>
          <Route path="/administrar-solicitudes" element={<UserRequestPague/>} />
        </Routes>
        <Footer/>
      </div>
    </Router>
    </AuthProvider>
  );
};

export default App;
