import React from 'react';

const NormatividadNacionalSaludAmbiental: React.FC = () => {
    return (
            <div className="overflow-x-auto p-2 sm:p-8 mx-auto">
                <table className="w-full border border-collapse">
                <thead>
                        <tr>
                            <th colSpan={3}  className="bg-primary text-textColor py-4 text-center">
                                Normatividad Nacional
                            </th>
                        </tr>
                        <tr>
                            <th colSpan={3}  className="bg-gris2 py-4 text-center">
                                Salud Ambiental
                            </th>
                        </tr>
                        <tr className="bg-gris1">
                            <th className="border px-4 py-2 text-center">
                                Norma
                            </th>
                            <th className="border px-4 py-2 text-center">
                                Institución
                            </th>
                            <th className="border px-4 py-2 text-center">
                                Descripción
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="bg-gris1">
                            <td className="border px-4 py-2 text-center">
                                <a href="https://www.minvivienda.gov.co/sites/default/files/normativa/3550%20-%202008.pdf" target="_blank" className="text-blue-600 underline">Lineamientos para la formulación de la política integral de salud ambiental con énfasis en los componentes de calidad del aire, calidad de agua y seguridad química</a>
                                <p>CONPES 3550/2008</p>
                            </td>
                            <td className="border px-4 py-2 text-center">Consejo Nacional de Política Económica y Social -CONPES</td>
                            <td className="border px-4 py-2">
                                <p className="ml-4">Tiene por objeto fortalecer la gestión integral para la prevención, manejo y control de diferentes factores ambientales que tienen el potencial de originar efectos adversos en la salud humana, enfatizando especialmente en los componentes de calidad del aire en exteriores e interiores.</p>
                            </td>
                        </tr>
                        <tr className="bg-gris1">
                            <td className="border px-4 py-2 text-center">
                                <a href="https://www.minsalud.gov.co/Normatividad_Nuevo/Resolución%20No.%201035%20de%202022.pdf" target="_blank" className="text-blue-600 underline">
                                Plan Decenal de Salud Pública 2022-2031.
                                </a>
                                <p>Resolución 1035 de 2022</p>
                            </td>
                            <td className="border px-4 py-2 text-center">Ministerio de Salud y Protección Social</td>
                            <td className="border px-4 py-2">
                                <p className="ml-4">Se establece el Pilar de Salud Ambiental como Pilar fundamental de la salud pública y su desarrollo en el Plan Decenal de Salud Pública.</p>
                                <p className="ml-4">Se reconoce el derecho a un ambiente sano incluye el reconocimiento del derecho a un aire limpio, a agua potable, ecosistemas saludables, a un clima estable y a alimentos sano  a implementación de políticas, planes y programas para la reducción de la contaminación del aire ambiente e interior proveniente de diferentes fuentes es una apuesta clave en salud pública para la reducción de los impactos en salud y la carga de morbilidad y mortalidad relacionados con esta contaminación.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
    );
}
export default NormatividadNacionalSaludAmbiental;
